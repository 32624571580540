<template>
    <div class="businessCard">
        <div class="header">
            <div class="card">
                <div class="logo">
                    <i class="iconfont icon-logo"></i>
                    <span>小鹅通</span>
                </div>
                <div class="cardInfo">
                    <div class="avator"></div>
                    <div class="info">
                        <p class="name">{{nickInfo.user_name || '--'}}<i class="iconfont icon-renzheng"></i></p>
                        <p>{{nickInfo.title || '--'}}</p>
                    </div>
                </div>
                <div class="userinfo">
                    <div class="user"><i class="iconfont icon-gengduobeifen25"></i>{{nickInfo.phone|| '--'}}</div>
                    <div class="user"><i class="iconfont icon-youjian1"></i>{{nickInfo.email|| '--'}}</div>
                    <div class="user"><i class="iconfont icon-gengduobeifen23"></i>{{nickInfo.address|| '--'}}/8楼</div>
                </div>
            </div>
            <div class="operation">
                <p class="share" @click="create">分享名片</p>
                <a class="copy" 
                :href="'tel:'+nickInfo.phone"
                >拨打手机号码</a>
            </div>
            <div class="contactType">
                <ul>
                    <li>
                        <a :href="'tel:'+nickInfo.phone">
                            <p class="contactKey">
                                <i class="iconfont icon-gengduobeifen25"></i>
                                打电话</p>
                            <p class="contactValue">{{nickInfo.phone || '--'}}</p>
                        </a>
                    </li>
                    <li class="wechat" :data-clipboard-text="nickInfo.wechat" @click="copyWechat('.wechat')">
                        <p class="contactKey">
                            <i class="iconfont icon-lianjie"></i>
                            加我微信</p>
                        <p class="contactValue">{{nickInfo.wechat || '--'}}</p>
                    </li>
                    <li class="email" :data-clipboard-text="nickInfo.email" @click="copyWechat('.email')">
                        <p class="contactKey">
                            <i class="iconfont icon-youjian1"></i>
                            邮箱</p>
                        <p class="contactValue">{{nickInfo.email || '--'}}</p>
                    </li>
                    <li class="email" :data-clipboard-text="nickInfo.address" @click="copyWechat('.email')">
                        <p class="contactKey">
                            <i class="iconfont icon-gengduobeifen23"></i>
                            地址</p>
                        <p class="contactValue">{{nickInfo.address || '--'}}</p>
                    </li>
                </ul>
            </div>
            <div class="operation other">
                <div class="left">
                    <div class="avator">
                        <img v-for="list in nickInfo.visit_list"
                         :src="list.pic_url" alt="">
                    </div>
                    <div class="person">{{nickInfo.visit_total}}人浏览</div>
                </div>
                <div class="right">
                    <span><i class="iconfont icon-dianzan1"></i>{{nickInfo.sale_total_click_num}}</span>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="introdution">
                <div class="title">我的简介</div>
                <div class="introContent">
                    <div class="words">
                        {{nickInfo.introduction}}
                    </div>
                    <ul class="label">
                        <li v-for="item in nickInfo.tags" :key="item.tag_id"
                        :class="item.is_click == 1?'active':''">
                        <i class="iconfont icon-dianzan1"></i>
                            {{item.tag_name}} {{item.click_num}}</li>
                    </ul>
                </div>
            </div>
               <div class="introdution">
                <div class="title">我的照片</div>
                <div class="imgList">
                    <img v-for="pic in nickInfo.pics" :key="pic.pic_id" 
                    :src="pic.pic_url" alt="">
                </div>
            </div>
        </div>
        <pop-dialog @cancle="popupVisible = false"
         :popupVisible="popupVisible"></pop-dialog>   
         <div class="shareBg" v-if="shareBg">
            <div class="poster" v-if="showPoster">
                 <original-html id="poster"></original-html>
            </div>
            <div class="posterImg">
                <i class="iconfont icon-xiaohaoshanchu" @click="closePoster"></i>
                <img :src="posterImg" alt="">
                <div class="tips">长按图片保存至相册并分享</div>
            </div>
             
         </div>
        
    </div>
</template>
<script>
import popDialog from '../components/popDialog.vue'
import originalHtml from '../components/originalHtml.vue'
import {setToken,getToken} from '@/util/util'
import html2canvas from 'html2canvas'
export default {
    name:'card',
    data(){
        return{
            popupVisible:false,
            sale_channel:'',
            nickInfo:{},
            showPoster:true,
            posterImg:'',
            shareBg:false,
        }
    },
    components:{
        popDialog,originalHtml
    },
    created(){
        console.log('process.env.TARGssET',process.env)
        // this.$Indicator.open('加载中');
        
    },
    mounted(){
        this.getUserInfo();
    },
    methods:{
        create(){
            this.shareBg = true;
            setTimeout(() => {
                this.createPoster();
            }, 100);
        },
        closePoster(){
            this.shareBg = false;
        },
        //生成海报
        createPoster() {
            const vm = this;
            const domObj = document.getElementById("poster");
            html2canvas(domObj, {
                useCORS: true,
                allowTaint: false,
                logging: false,
                width: domObj.clientWidth, //dom 原始宽度
                height: domObj.clientHeight,
                scrollY: 0,
                scrollX: 0,
            }).then(function (canvas) {
                vm.posterImg = canvas.toDataURL("image/png");
                vm.showPoster = false; // 隐藏选择模板页
            });
        },
        getUserInfo(){
            let sale_channel = this.$route.query.sale_channel || getToken('sale_channel')
            let params = {
                sale_channel:sale_channel
            }
            this.$http.post('/user_service/sale/get_sale_base_info',params).then(res=>{
                console.log('resasdas',res);
                this.$Indicator.close();
                if(res.code == 0){
                    this.nickInfo = res.data;
                    setToken('saleInfo',res.data);
                    setToken('sale_channel',sale_channel)
                }
            }).catch(err=>{
                this.$Indicator.close();
                console.log('errr',err)
            })

        },
        copyWechat(className){
            this.copy(className);
        },
        copy(className){
            var clipboard = new this.Clipboard(className); //在main.js中引用
            clipboard.on("success", e => {
                // 释放内存
                this.$Toast('复制成功')
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$Toast({
                message: "该浏览器不支持自动复制",
                type: "warning"
                });
                // 释放内存
                clipboard.destroy();
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.businessCard{
    width:100%;
    background: #f5f5f5;
    padding-bottom:.6rem;
    font-size:.28rem;
    margin-bottom:$tabHeight;
    .header{
        background: #fff;
        padding-bottom:1px;
        .card{
            width:92%;
            margin:.32rem auto .48rem;
            background: #fff;
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
            border-radius: .18rem;
            position: relative;
            .logo{
                position:absolute;
                text-indent: .4rem;
                color:$colorDefault;
                font-size: .24rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                right:.32rem;
                top:.32rem;
                display: flex;
                // background: url('../../../assets/logo.png') no-repeat left center;
                // background-size:.32rem .32rem;
                i{
                    width:.32rem;
                    height:.32rem;
                    color:#1472FF;
                    display: inline-block;
                    margin:0 4px 0 0 ;
                }

            }
            .cardInfo{
                display: flex;
                padding:.32rem .48rem 0;
                .avator{
                    width:1.2rem;
                    height:1.2rem;
                    border-radius: 50%;
                    background: red;
                    img{
                        width:100%;
                        height:100%;
                    }
                }
                .info{
                    margin-left:.24rem;
                    p{
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2B2B2B;
                        margin-top:.08rem;
                    }
                    .name{
                        font-size:.32rem;
                        margin-top:.18rem;
                        position: relative;
                        display: inline-block;
                        i{
                            width:.32rem;
                            height:.32rem;
                            margin-left:.16rem;
                            color:#1472FF;
                        }
                    }
                }
            }
            .userinfo{
                padding:.32rem .48rem .32rem;
                .user{
                    margin-top:.16rem;
                    color:#2B2B2B;
                    // padding-left:.48rem;
                    // display: block;
                    i{
                        width:.32rem;
                        height:.32rem;
                        margin-right:.16rem;
                        color:rgb(164,164,164);
                    }
                }
              

            }
        }
        .operation{
            width:92%;
            margin:0 4% .48rem 4%;
            display: flex;
            color:$colorActive;
            text-align: center;
            .share{
                margin-right:.2rem;
                background: #fff;
                border:1px solid #ccc;
                color:#333;
                flex:1;
                padding:.18rem 0;
                border-radius: .4rem;
            }
            .copy{
                margin-left:.2rem;
                background: $colorActive;
                color:#fff;
                flex:1;
                padding:.18rem 0;
                border-radius: .4rem;
                display: block;
                text-decoration: none;
            }
            .left{
                flex:1;
                height:.4rem;
                line-height: .4rem;
                font-size:.24rem;
                color:#333333;
                .avator{
                    float: left;
                    img{
                        width:.4rem;
                        height:.4rem;
                        border-radius: 4px;
                        margin-right:5px;
                        background: red;
                    }
                }
                .person{
                    float: left;
                }
            }
            .right{
                // width:1rem;
                font-size:.28rem;
                color:$colorActive;
                text-align: right;
                padding-top:1px;
                span{
                    // padding-left:.36rem;
                    // background: url('../../../assets/fabulous.png') no-repeat left center;
                    // background-size:.32rem .32rem;
                    i{
                        margin-right:4px;
                    }
                }

            }
        }
        .other{
             margin:.4rem 4% .48rem;
        }
        .contactType{
            width:96%;
            overflow-x: auto;
            padding-left:4%;
            ul{
                width:100%;
                white-space: nowrap;
                height:1.32rem;
                li{
                    display: inline-block;
                    font-size:.24rem;
                    padding:.16rem .32rem;
                    background: #fff;
                    min-width:1.6rem;
                    margin-right:.24rem;
                    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    color:#333333;
                    .contactKey{
                        color:$colorDefault;
                        display: flex;
                        align-items: center;
                        // padding-left:.48rem;
                        i{
                            font-size:.32rem;
                            margin:0 .16rem 0 -.05rem;

                        }
                    }
                    .contactValue{
                        margin-top:4px;
                        color:#333;
                    }
                }
            }
        }
        .phone{
            background: url('../../../assets/phone.png') no-repeat left center;
            background-size:.32rem .32rem;
        }
        .address{
            background: url('../../../assets/address.png') no-repeat left center;
            background-size:.32rem .32rem;
        }
        .email{
            background: url('../../../assets/email.png') no-repeat left center;
            background-size:.32rem .32rem;
        }
        .wechat{
            background: url('../../../assets/wechat.png') no-repeat left center;
            background-size:.32rem .32rem;
        }
        a{
            display: block;
            text-decoration: none;
            &:focus {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-tap-highlight-color: transparent;
                outline: none;
            }

        }
    }
    .content{
        width:92%;
        margin:0 auto;
        .introdution{
            width:100%;
            .title{
                font-size:.32rem;
                font-weight: 500;
                position: relative;
                margin:.72rem 0 .32rem;
                font-family: PingFangSC-Medium, PingFang SC;
                text-indent:.24rem;
                height:.32rem;
                display: flex;
                align-items: center;
                &:after{
                    content:'';
                    height:.32rem;
                    width: .08rem;
                    background: $colorActive;
                    position: absolute;
                    border-radius: 2px;
                    top:1px;
                    left:0;

                }
            }
            .introContent{
                background: #fff;
                border-radius: 5px;
                padding:.32rem;
                .words{
                    text-indent:.56rem;
                    color:#333333;
                }
                .label{
                    margin-top:.54rem;
                    li{
                        display: inline-block;
                        border:1px solid #ccc;
                        color:#333;
                        margin:.16rem .24rem 0 0;
                        border-radius: .4rem;
                        padding:.08rem .16rem;
                        text-indent:.4rem;
                        i{

                        }
                        // background: url('../../../assets/fabulous.png') no-repeat 8px center;
                        // background-size:.32rem .32rem;
                        
                    }
                    .active{
                        border:1px solid $colorActive;
                        color:$colorActive;
                    }
                }
                
            }
            .imgList{
                img{
                    width:100%;
                    height:4rem;
                    display: block;
                    margin-bottom:.2rem;
                    background-color: red;
                    border-radius: .08rem;
                }

            }

        }

    }
    .shareBg{
        width:100%;
        height:100vh;
        background: rgba(0,0,0,.5);
        position: fixed;
        top:0;
        left:0;
        z-index: 10;
        .poster{
            position: absolute;
            top:0;
            left:0;
            opacity: 0;
            // transform: translate(-65%, -50%);
            // margin:-50% -50%;
            // transform: translate(-50% -50%);
        }
        .posterImg{
            height:100vh;
            margin:0 .88rem;
            position: relative;
            padding-top:2.52rem;
            border-radius: .16rem;
            position: relative;
            img{
                width:100%;
                border-radius: .16rem;
            }
            i{
                position: absolute;
                top:2rem;
                color:#fff;
                font-size:.32rem;
                right:0;
            }
            .tips{
                width:100%;
                text-align: center;
                font-size: .24rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-top:.32rem;
            }
        }

    }
   
    
}
    
</style>
