<template>
    <mt-popup
        v-model="popupVisible"
        position="bottom"
        class="popup"
        :closeOnClickModal="false"
        popup-transition="popup-fade">
        <div class="dialog">
            <a href="tel:13256278829" class="call" @click="cancle">呼叫</a>
            <div class="call copyPhone" @click="copyWord"
            :data-clipboard-text="13242428697">复制</div>
            <div class="cancle" @click.stop="cancle">取消</div>
        </div>
    </mt-popup>
</template>
<script>
export default {
    name:'popDialog',
    props:{
        popupVisible:{
            type:Boolean,
            default:false,
        }
    },
    data(){
        return{
        }
    },
    methods:{
        copyWord(){
            console.log('ewqeq')
    　　　  var clipboard = new this.Clipboard(".copyPhone"); //在main.js中引用
            clipboard.on("success", e => {
                // 释放内存
                this.$emit('cancle');
                setTimeout(() => {
                    this.$Toast('复制成功')
                    clipboard.destroy();
                }, 1);
                
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$Toast({
                message: "该浏览器不支持自动复制",
                type: "warning"
                });
                // 释放内存
                clipboard.destroy();
            });
        },
        cancle(){
            this.$emit('cancle')
        }
    }
}
</script>
<style lang="scss" scoped>
.popup{
    width:100%;
    border-radius: 10px 10px 0 0;
    .dialog{
        width:100%;
        background: #f5f5f5;
        border-radius: 10px 10px 0 0;
        overflow:hidden;
        .call{
            display: block;
            text-decoration: none;
            padding:13px 0 15px;
            text-align: center;
            color:#333333;
            font-family: PingFangSC-Regular, PingFang SC;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08);
            border-bottom:1px solid #f5f5f5;
            background: #fff;
        }
        .cancle{
            margin-top:10px;
            padding:13px 0 15px;
            text-align: center;
            color:#333333;
            font-family: PingFangSC-Regular, PingFang SC;
            background: #fff;
        }
    }
}
    
</style>