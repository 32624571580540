import { render, staticRenderFns } from "./originalHtml.vue?vue&type=template&id=61f3515e&scoped=true&"
var script = {}
import style0 from "./originalHtml.vue?vue&type=style&index=0&id=61f3515e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f3515e",
  null
  
)

export default component.exports